export enum EPosition {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum EButtonStyle {
  AREA = 'area',
  DEFAULT = 'default',
  LIGHT = 'light',
  INVERTED = 'inverted',
  CTA = 'cta',
  CTA_DARK = 'cta-dark',
  FORMREPEATER = 'formrepeater',
  FORMREPEATER_COMPLAINT_FORM = 'formrepeaterComplaintForm',
}

export enum EButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
  LINK = 'link',
  ROUTERLINK = 'routerlink',
  PLAIN = 'plain',
}
